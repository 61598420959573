.root {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	gap: var(--hdsplus-spacing-07);
	
	@media (--medium-up) {
		justify-content: flex-start;
	}
}

.icon {
	height: 100%;
	display: flex;
}

.mobile {
	display: flex;

	@media (--medium-up) {
		display: none;
	}
}

.tablet {
	display: none;

	@media (--medium-up) {
		display: flex;
	}

	@media (--large) {
		display: none;
	}
}

.desktop {
	display: none;

	@media (--large) {
		display: flex;
	}
}